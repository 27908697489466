import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  methods: {
    exportDataPdf() {
      const doc = new jsPDF();
      doc.setFont("helvetica");
      doc.setFontSize(12);

      doc.text(
        70,
        15,
        `Analytics Report for ${
          this.productEnums[this.$route.params.id]
        } product`
      );

      doc.text(7, 40, `average dots are:  ${this.average_dot}`);

      doc.text(
        7,
        47,
        `growth of year ${this.selectedYear} is :  ${this.growth.percentage}`
      );
      doc.text(
        7,
        54,
        `YTD of year ${this.selectedYear} is :  ${this.ytd.percentage}`
      );

      //status chart
      // doc.text("Status Analytics Report", 15, 10);
      doc.text(7, 70, `Status Analytics Report`);
      doc.autoTable({
        head: [["approved", "rejected", "pending"]],
        body: [this.statusChartSeries],
        startY: 73,
        margin: { horizontal: 7 },
      });

      //Cases per PS chart
      // doc.text("Cases per PS", 15, 10);
      doc.text("Cases per PS", 7, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        head: [this.casesPSChartOptions.xaxis.categories],
        body: [this.casesPSChartSeries[0].data],
        startY: doc.autoTable.previous.finalY + 13,
        margin: { horizontal: 7 },
      });

      // Cases per HCP
      doc.text("Cases per HCP", 7, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        head: [this.casesHCPChartOptions.xaxis.categories],
        body: [this.casesHCPChartSeries[0].data],
        startY: doc.autoTable.previous.finalY + 13,
        margin: { horizontal: 7 },
      });

      // Cases per Payer
      doc.text("Cases per Payer", 7, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        head: [this.casesPayerChartOptions.xaxis.categories],
        body: [this.casesPayerChartSeries[0].data],
        startY: doc.autoTable.previous.finalY + 13,
        margin: { horizontal: 7 },
      });

      //get patient list for product
      this.itemspatientList.forEach(function (el) {
        delete el.created_at;
      });

      //patient list table Header
      var tableHeader = Object.keys(this.itemspatientList[0]);

      //patient list table body
      let arrayLists = [];
      this.itemspatientList.forEach(function (el) {
        arrayLists.push(Object.values(el));
      });

      doc.text("Patients List", 7, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        head: [tableHeader],
        body: arrayLists,
        startY: doc.autoTable.previous.finalY + 13,
        margin: { horizontal: 7 },
      });

      doc.save(
        `product-${this.productEnums[this.$route.params.id]}-${
          this.selectedYear
        }.pdf`
      );
    },
  },
};
