export default {
  data: () => ({
    casesPayerChartSeries: [
      {
        name: "Cases per Payer",
        data: [],
      },
    ],
    casesPayerChartOptions: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
      },
      colors: ["#26BBE3"],

      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth:  null,

        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
    },
  }),
};
