<template>
  <section class="conOfPatientList">
    <div class="d-flex mb-5">
      <div class="globalSectionHeader" v-if="itemspatientList && itemspatientList.length > 0">
        Patients
      </div>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="textBtnRed"
            text
            :ripple="false"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
            Add New
          </v-btn>
        </template>
        <v-card class="conOfPopup">
          <v-card-title>
            <span class="text-h5 callbacks modalTitle">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text class="conFormSubmit">
            <v-container>
              <v-row>
                <v-col md="6" cols="12">
                  <v-select
                    outlined
                    v-model="formItem.payer_id"
                    :items="formList.payers"
                    item-text="name"
                    item-value="id"
                    label="Select Payer"
                    hide-details="auto"
                    required
                    :error-messages="payer_idErrors"
                    @input="$v.formItem.payer_id.$touch()"
                    @blur="$v.formItem.payer_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12">
                  <v-select
                    outlined
                    v-model="formItem.hcp_id"
                    :items="formList.hcps"
                    item-text="name"
                    item-value="id"
                    label="Select HCP"
                    hide-details="auto"
                    required
                    :error-messages="hcp_idErrors"
                    @input="$v.formItem.hcp_id.$touch()"
                    @blur="$v.formItem.hcp_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12">
                  <v-select
                    outlined
                    v-model="formItem.ps_id"
                    :items="formList.pss"
                    item-text="name"
                    item-value="id"
                    label="Select PS"
                    hide-details="auto"
                    required
                    :error-messages="ps_idErrors"
                    @input="$v.formItem.ps_id.$touch()"
                    @blur="$v.formItem.ps_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12">
                  <div class="conDatePicker">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          solo
                          v-model="date"
                          label="Select Date of DOT"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          required
                          :error-messages="dateErrors"
                          @input="$v.formItem.ps_id.$touch()"
                          @blur="$v.formItem.ps_id.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu = false"
                        type="month"
                        :min="minDate"
                        :max="maxDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  v-if="$route.params.id == 2 || $route.params.id == 3"
                >
                  <v-select
                    outlined
                    v-model="formItem.indication_id"
                    :items="formList.indications"
                    item-text="name"
                    item-value="id"
                    label="Select indication"
                    hide-details="auto"
                    required
                    :error-messages="indication_idErrors"
                    @input="$v.formItem.indication_id.$touch()"
                    @blur="$v.formItem.indication_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                  v-if="
                    $route.params.id == 1 ||
                    $route.params.id == 4 ||
                    $route.params.id == 5 ||
                    $route.params.id == 6
                  "
                >
                  <v-select
                    outlined
                    v-model="formItem.treatment_line_id"
                    :items="formList.lines"
                    item-text="name"
                    item-value="id"
                    label="Select Treatment Line"
                    hide-details="auto"
                    required
                    :error-messages="treatment_line_idErrors"
                    @input="$v.formItem.treatment_line_id.$touch()"
                    @blur="$v.formItem.treatment_line_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12" v-if="$route.params.id == 1">
                  <v-select
                    outlined
                    v-model="formItem.infusion_id"
                    :items="formList.centers"
                    item-text="name"
                    item-value="id"
                    label="Select Infusion Center"
                    hide-details="auto"
                    required
                    :error-messages="infusion_idErrors"
                    @input="$v.formItem.infusion_id.$touch()"
                    @blur="$v.formItem.infusion_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12">
                  <v-select
                    outlined
                    v-model="formItem.city_id"
                    :items="formList.cities"
                    item-text="name"
                    item-value="id"
                    label="Select City"
                    hide-details="auto"
                    required
                    :error-messages="city_idErrors"
                    @input="$v.formItem.city_id.$touch()"
                    @blur="$v.formItem.city_id.$touch()"
                  ></v-select>
                </v-col>
                <v-col md="6" cols="12">
                  <v-select
                    outlined
                    v-model="formItem.status"
                    :items="statusList"
                    item-text="name"
                    item-value="id"
                    label="Select status"
                    hide-details="auto"
                    required
                    :error-messages="statusErrors"
                    @input="$v.formItem.status.$touch()"
                    @blur="$v.formItem.status.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="pb-10">
            <v-spacer></v-spacer>
            <v-btn class="btnDismissed" tile @click="close"> Cancel </v-btn>
            <v-btn
              class="btnPrimaryBink"
              tile
              @click="saveItem"
              :disabled="isLoadingSave"
              :loading="isLoadingSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card class="conConfirmPopup">
          <v-card-title>
            <span class="text-h5 modalTitle">Are you sure</span>
          </v-card-title>
          <v-card-text class="textConfirm"
            >Are you sure you want to delete this item?</v-card-text
          >
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn class="btnDismissed" tile @click="closeDelete">Cancel</v-btn>
            <v-btn
              class="btnPrimaryBink"
              tile
              @click="deleteItemConfirm"
              :disabled="isLoadingDelete"
              :loading="isLoadingDelete"
              >Delete</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <section v-if="itemspatientList && itemspatientList.length > 0">
      <v-data-table :headers="headers" :items="itemspatientList" class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
          <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
      </v-data-table>

      <div
        class="text-center mt-3"
        v-if="pagination.total > pagination.per_page"
      >
        <v-pagination
          v-model="pagination.current_page"
          :length="pagination.last_page"
          @input="goToPage(pagination.current_page)"
        ></v-pagination>
      </div>
    </section>
    <EmptyState v-else></EmptyState>
    <div class="loaderContainer" v-if="isLoadingGlobal">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </section>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

import EmptyState from "@/modules/shared/components/emptystate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import patientMixin from "../../../mixins/patient";
export default {
  mixins: [validationMixin, patientMixin],
  validations() {
    const validations = {
      formItem: {
        payer_id: { required },
        hcp_id: { required },
        city_id: { required },
        ps_id: { required },
        status: { required },
      },
      date: { required },
    };
    if (this.requireOfIndication) {
      validations.formItem.indication_id = {
        required,
      };
    }
    if (this.requireOfInfustion) {
      validations.formItem.infusion_id = {
        required,
      };
    }
    if (this.requireOfTreatmentLine) {
      validations.formItem.treatment_line_id = {
        required,
      };
    }
    return validations;
  },
  data: () => ({
    minDate: null,
    maxDate: null,
    dialog: false,
    menu: false,
    dialogDelete: false,
    isLoadingDelete: false,
    isLoadingSave: false,
    formItem: {},
    date: null,
    defaultItem: {},
    statusList: [
      {
        id: 0,
        name: "Pending",
      },
      {
        id: 1,
        name: "Approved",
      },
      {
        id: 2,
        name: "Rejected",
      },
    ],
    headers: [],
    itemspatientList: [],
    editedIndex: -1,
    formList: [],
    isLoadingForm: false,
    isLoadingGlobal: false,
    requireOfIndication: false,
    requireOfInfustion: false,
    requireOfTreatmentLine: false,
    pagination: {
      current_page: 1,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New" : "Edit Item";
    },
    payer_idErrors() {
      const errors = [];
      if (!this.$v.formItem.payer_id.$dirty) return errors;
      !this.$v.formItem.payer_id.required && errors.push("payer is required.");
      return errors;
    },
    hcp_idErrors() {
      const errors = [];
      if (!this.$v.formItem.hcp_id.$dirty) return errors;
      !this.$v.formItem.hcp_id.required && errors.push("hcp is required.");
      return errors;
    },
    city_idErrors() {
      const errors = [];
      if (!this.$v.formItem.city_id.$dirty) return errors;
      !this.$v.formItem.city_id.required && errors.push("city is required.");
      return errors;
    },
    ps_idErrors() {
      const errors = [];
      if (!this.$v.formItem.ps_id.$dirty) return errors;
      !this.$v.formItem.ps_id.required && errors.push("PS is required.");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.formItem.status.$dirty) return errors;
      !this.$v.formItem.status.required && errors.push("status is required.");
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("date is required.");
      return errors;
    },
    indication_idErrors() {
      const errors = [];
      if (!this.$v.formItem.indication_id.$dirty) return errors;
      !this.$v.formItem.indication_id.required &&
        errors.push("indication is required.");
      return errors;
    },
    infusion_idErrors() {
      const errors = [];
      if (!this.$v.formItem.infusion_id.$dirty) return errors;
      !this.$v.formItem.infusion_id.required &&
        errors.push("infusion is required.");
      return errors;
    },

    treatment_line_idErrors() {
      const errors = [];
      if (!this.$v.formItem.treatment_line_id.$dirty) return errors;
      !this.$v.formItem.treatment_line_id.required &&
        errors.push("treatment line is required.");
      return errors;
    },
  },
  components: {
    EmptyState,
  },
  methods: {
    goToPage(page) {
      console.log(page);
      // this.pathByOaramSearch();
      this.pagination.current_page = page;
      this.initializePatientList(this.$route.params.id, this.$route.query.year);
      window.scrollTo(0, 0);
    },
    loadFormData(id) {
      this.formList = [];
      this.isLoadingForm = true;

      apiServices.get(`admin/product/${id}/show-form`).then((res) => {
        if (res) {
          this.isLoadingForm = false;
          this.formList = res.data;
        }
      });
    },
    datepickerValidate() {
      let currentYear = new Date().getFullYear();
      this.maxDate = `${currentYear}-12`;
      this.minDate = `${currentYear - 2}-01`;
    },
    editItem(item) {
      this.isLoadingGlobal = true;

      apiServices
        .get(`admin/product/${this.$route.params.id}/get-patient/${item.id}`)
        .then((res) => {
          if (res) {
            this.editedIndex = this.itemspatientList.indexOf(item);
            this.formItem = res.data;
            let date = `${res.data.year}-${res.data.month}`;
            this.date = date;
            this.isLoadingGlobal = false;
            this.dialog = true;
          }
        });
    },

    deleteItem(item) {
      this.editedIndex = this.itemspatientList.indexOf(item);
      this.formItem = item;
      this.formItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.isLoadingDelete = true;
      apiServices
        .delete(
          `admin/product/${this.$route.params.id}/delete-patient/${this.formItem.id}`
        )
        .then((res) => {
          if (res) {
            this.itemspatientList.splice(this.editedIndex, 1);
            this.$emit('patientChange')
            this.isLoadingDelete = false;
            this.closeDelete();
          } else {
            this.isLoadingDelete = false;
            this.closeDelete();
          }
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
          this.date = null,
          this.editedIndex = -1;
        });
      }, 100);
    },

    closeDelete() {
      this.dialogDelete = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
          this.date = null,
          this.editedIndex = -1;
        });
      }, 100);
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      let dateSelected = new Date(this.date);
      let month = dateSelected.getMonth();
      let year = dateSelected.getFullYear();

      this.formItem.product_id = parseInt(this.$route.params.id);
      this.formItem.month = month;
      this.formItem.year = year;
      // delete this.formItem.date;
      // Object.keys(this.formItem).forEach(k => (!this.formItem[k]) && delete this.formItem[k]);

      apiServices.post(`admin/add-patient`, this.formItem).then((res) => {
        if (res) {
          // this.itemspatientList.push(res.data);
          this.initializePatientList(this.$route.params.id, this.$route.query.year);
          this.$emit('patientChange')
          this.isLoadingSave = false;
          this.close();
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      let dateSelected = new Date(this.date);
      let month = dateSelected.getMonth();
      let year = dateSelected.getFullYear();

      this.formItem.product_id = parseInt(this.$route.params.id);
      let product_id = this.formItem.id;

      this.formItem.month = month;
      this.formItem.year = year;
      // delete this.formItem.date;
      delete this.formItem.id;

      let formItem = this.formItem;
      Object.keys(formItem).forEach(
        (k) => formItem[k] == null && delete formItem[k]
      );

      apiServices
        .post(`admin/edit-patient/${product_id}`, formItem)
        .then((res) => {
          if (res) {
            Object.assign(this.itemspatientList[this.editedIndex], res.data);
            this.$emit('patientChange')
            this.isLoadingSave = false;
            this.close();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    watchRequiredItem(id) {
      switch (parseInt(id)) {
        case 1:
          this.requireOfIndication = false;
          this.requireOfInfustion = true;
          this.requireOfTreatmentLine = true;
          break;
        case 2:
          this.requireOfIndication = true;
          this.requireOfInfustion = false;
          this.requireOfTreatmentLine = false;
          break;
        case 3:
          this.requireOfIndication = true;
          this.requireOfInfustion = false;
          this.requireOfTreatmentLine = false;
          break;
        case 4:
          this.requireOfIndication = false;
          this.requireOfInfustion = false;
          this.requireOfTreatmentLine = true;
          break;
        case 5:
          this.requireOfIndication = false;
          this.requireOfInfustion = false;
          this.requireOfTreatmentLine = true;
          break;
        case 6:
          this.requireOfIndication = false;
          this.requireOfInfustion = false;
          this.requireOfTreatmentLine = true;
          break;
      }
    },
  },
  created() {
    this.datepickerValidate();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.query": {
      handler: function () {
        this.initializePatientList(this.$route.params.id, this.$route.query.year);
        this.loadFormData(this.$route.params.id);
        this.watchRequiredItem(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_patient-list.scss";
</style>
