export default {
  data: () => ({
    casesPSChartSeries: [
      {
        name: "Cases per PS",
        data: null,
      },
    ],
    casesPSChartOptions: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
      },
      colors: ["#4981FD"],

      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: null,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
    },
  }),
};
