import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
    methods: {
        initializePatientList(id, year) {
          this.itemspatientList = [];
    
          apiServices
            .get(
              `admin/product/${id}/patients?page=${this.pagination.current_page}?year=${year}`
            )
            .then((res) => {
              if (res) {
                this.itemspatientList = res.data;
                this.pagination = res.meta;
                this.headers = res.headers;
              }
            });
        },
    }    
}