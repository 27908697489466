<template>
  <section class="reportProductsSidecard d-flex fill-height">
    <section class="contentSection scrollbar mx-3">
      <section class="conSectionChart" ref="testHtml">
        <v-container fluid v-if="!flagGetMainCharts && !noChartsExist">
          <v-row>
            <v-col md="4" cols="12">
              <v-select
                outlined
                v-model="selectedYear"
                :items="listOfYears"
                label="select Year"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
                @change="changeReportYear()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <div class="globalSectionHeader">Analytics Report</div>
            </v-col>
            <v-col md="6" cols="12">
              <div class="text-right">
                <v-btn class="btnGrayOutline" @click="exportDataPdf" outlined>
                  <v-icon> mdi-file-pdf-box </v-icon>
                  export pdf
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" cols="12">
              <div class="conOfChart constatusChart">
                <v-card
                  class="mx-auto pa-3"
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <VueApexCharts
                    type="donut"
                    :options="statusChartOptions"
                    :series="statusChartSeries"
                  />
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfChart fill-height">
                <v-card
                  class="
                    fill-height
                    mx-auto
                    pa-3
                    d-flex
                    flex-column
                    justify-space-around
                  "
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <div>
                    <div class="progressBarHeader">
                      {{ this.selectedYear }} YTD
                    </div>
                    <div
                      :class="
                        ytd.percentage > 0
                          ? 'prgressPossitiveVal'
                          : 'prgressNegativeVal'
                      "
                    >
                      {{ ytd.percentage > 0 ? "+" : "" }}{{ ytd.percentage }}%
                    </div>
                  </div>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-progress-linear
                          v-bind="attrs"
                          v-on="on"
                          :value="ytd.percentage"
                          color="#003696"
                          height="16"
                        ></v-progress-linear>
                      </template>
                      <span class="toolTopStyle">{{
                        ytd.ytd_number_of_patients
                      }}</span>
                    </v-tooltip>
                    <div
                      class="progressItemsCompare d-flex justify-space-between"
                    >
                      <div class="fItem item">{{ this.selectedYear }}</div>
                      <div class="sItem item">{{ this.selectedYear - 1 }}</div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfChart fill-height">
                <v-card
                  class="
                    fill-height
                    mx-auto
                    pa-3
                    d-flex
                    flex-column
                    justify-space-around
                  "
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <div>
                    <div class="progressBarHeader">
                      {{ this.selectedYear }} GROWTH
                    </div>
                    <div
                      :class="
                        growth.percentage > 0
                          ? 'prgressPossitiveVal'
                          : 'prgressNegativeVal'
                      "
                    >
                      <!-- -15% -->
                      {{ growth.percentage > 0 ? "+" : ""
                      }}{{ growth.percentage }}%
                    </div>
                  </div>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-progress-linear
                          v-bind="attrs"
                          v-on="on"
                          :value="growth.percentage"
                          color="#003696"
                          height="16"
                        ></v-progress-linear>
                      </template>
                      <span>{{ growth.total_number_of_patients }}</span>
                    </v-tooltip>

                    <div
                      class="progressItemsCompare d-flex justify-space-between"
                    >
                      <div class="fItem item">{{ this.selectedYear }}</div>
                      <div class="sItem item">LREP</div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfChart">
                <v-card
                  class="mx-auto pa-3"
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <div class="headerOfBarChart mb-3">Cases per PS</div>
                  <VueApexCharts
                    type="bar"
                    :options="casesPSChartOptions"
                    :series="casesPSChartSeries"
                  />
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfChart">
                <v-card
                  class="mx-auto pa-3"
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <div class="headerOfBarChart mb-3">Cases per HCP</div>
                  <VueApexCharts
                    type="bar"
                    :options="casesHCPChartOptions"
                    :series="casesHCPChartSeries"
                  />
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfChart">
                <v-card
                  class="mx-auto pa-3"
                  max-width="100%"
                  tile
                  elevation="1"
                >
                  <div class="headerOfBarChart mb-3">Cases per Payer</div>
                  <VueApexCharts
                    type="bar"
                    :options="casesPayerChartOptions"
                    :series="casesPayerChartSeries"
                  />
                </v-card>
              </div>
            </v-col>
            <v-col md="4" cols="12">
              <div class="conOfAverageDots">
                <v-card
                  class="mx-auto ConOfCard"
                  max-width="100%"
                  tile
                  elevation="1"
                  min-height="270px"
                >
                  <div class="conOfAverageCard pa-6">
                    <div class="conImage">
                      <img src="@/assets/img/date.png" alt="logo" />
                    </div>
                    <div class="averageDot">Average DOT</div>
                    <div class="averageVal">{{ average_dot }}</div>
                  </div>
                </v-card>
              </div>
            </v-col>
            <v-col md="8" cols="12">
              <v-card
                class="mx-auto ConOfCard"
                max-width="100%"
                tile
                elevation="1"
                min-height="270px"
              >
                <GmapMap
                  :center="center"
                  :zoom="zoom"
                  :options="mapStyle"
                  ref="map"
                  style="width: 100%; height: 270px"
                >
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoPosition"
                    :opened="infoOpened"
                    @closeclick="infoOpened = false"
                  >
                        <div class="styleofWindow pa-2">
                          <div class="cityName">
                            {{ infoContent }}
                          </div>
                          <div class="infoTotalNum">
                              <span>total: </span>
                              <span>{{infoTotalNum}}</span>
                          </div>
                          <div class="infoPercentage">
                              <span>Percentage: </span>
                              <span>{{infoPercentage}}%</span>
                          </div>
                        </div>
                  </gmap-info-window>
                  <GmapCircle
                    v-for="(pin, index) in markers"
                    :key="index"
                    :center="JSON.parse(pin.position)"
                    :radius="30000"
                    :visible="true"
                    :options="{
                      fillColor: pin.color,
                      fillOpacity: 0.8,
                      strokeColor: pin.color,
                      strokeOpacity: 0.8,
                      circle: false,
                    }"
                    :clickable="true"
                    @mouseover="toggleInfo(pin, index)"
                    @mouseout="infoOpened = false"
                  ></GmapCircle>
                </GmapMap>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <div class="loaderContainer" v-if="flagGetMainCharts">
          <v-progress-circular
            :size="120"
            :width="4"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </section>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <PatientList @patientChange="changeInPatientList()" />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <SidebarProducts />
  </section>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

import StatusChart from "../../mixins/reportProductChart/StatusChart";
import CasesPerPS from "../../mixins/reportProductChart/CasesPerPS";
import CasesPerHCP from "../../mixins/reportProductChart/CasesPerHCP";
import CasesPerPayer from "../../mixins/reportProductChart/CasesPerPayer";

import ExportPdf from "../../mixins/exportPdf";
import MapCitiesBubbles from "../../mixins/mapCitiesSingleProduct";

import VueApexCharts from "vue-apexcharts";
import PatientList from "./patient-list";
import SidebarProducts from "../sideBarProducts/index.vue";

import productEnum from "../../../Setting/mixins/productEnums";
import patientMixin from "../../mixins/patient";


export default {
  mixins: [
    StatusChart,
    CasesPerPS,
    CasesPerHCP,
    CasesPerPayer,
    productEnum,
    patientMixin,
    ExportPdf,
    MapCitiesBubbles,
  ],
  data: () => ({
    ytd: null,
    growth: null,
    progressVal1: 30,
    progressVal2: 45,
    listData: [],
    listOfYears: [],
    selectedYear: new Date().getFullYear(),
    flagGetMainCharts: false,
    noChartsExist: false,
    average_dot: null,
    itemspatientList: [],
    headers: [],
    pagination: {
      current_page: 1,
    },
  }),

  methods: {
    changeInPatientList() {
      this.initialize(this.$route.params.id, this.selectedYear);
    },
    twoItemsToArray(array, filterEl1, filterEl2) {
      let array1 = [];
      let array2 = [];
      array.forEach((el) => {
        array1.push(el[filterEl1]);
        array2.push(el[filterEl2]);
      });
      return { array1, array2 };
    },
    getYearsFilterValidation() {
      let currentYear = new Date().getFullYear();
      this.listOfYears.push(currentYear, currentYear - 1, currentYear - 2);
      this.pathByOaramSearch();
    },
    changeReportYear() {
      this.pathByOaramSearch();
    },
    patientAnalytics(patients) {
      this.statusChartSeries = [];
      this.statusChartSeries.push(
        parseInt(patients[0].approved),
        parseInt(patients[0].rejected),
        parseInt(patients[0].pending)
      );
      let total =
        patients[0].approved + patients[0].rejected + patients[0].pending;
      this.statusChartOptions.plotOptions.pie.donut.labels.value.formatter = (
        value
      ) => ((value / total) * 100).toFixed(2) + "%";
    },
    checkArrayLength(len, propName) {

      switch (true) {
        case (len == 1):
          propName.plotOptions.bar.columnWidth = '8%';
          break;
        case  (len == 2):
          propName.plotOptions.bar.columnWidth = '15%';
          break;
        case  (len == 3):
          propName.plotOptions.bar.columnWidth = '22%';
          break;
        case (len > 3):
          propName.plotOptions.bar.columnWidth = '27%';
          break;
        case (len > 6 && len < 10):
          propName.plotOptions.bar.columnWidth = '35%';
          break;
        default:
          propName.plotOptions.bar.columnWidth = '50%';
      }
    },
    casesPSChart(numberOfPatient) {
      // this.totalNumberOfPatients = numberOfPatient.total_number_of_patients;
      let result = this.twoItemsToArray(numberOfPatient, "name", "total");
      this.checkArrayLength(result.array1.length, this.casesPSChartOptions)
      this.casesPSChartSeries[0].data = result.array2;
      this.casesPSChartOptions.xaxis.categories = result.array1;
      this.casesPSChartOptions.yaxis.categories = result.array1;
    },
    casesPayerChart(numberOfPatient) {
      let result = this.twoItemsToArray(numberOfPatient, "name", "total");
      this.checkArrayLength(result.array1.length, this.casesPayerChartOptions)
      this.casesPayerChartSeries[0].data = result.array2;
      this.casesPayerChartOptions.xaxis.categories = result.array1;
      this.casesPayerChartOptions.yaxis.categories = result.array1;
    },
    casesHCPChart(numberOfPatient) {
      let result = this.twoItemsToArray(numberOfPatient, "name", "total");
      this.checkArrayLength(result.array1.length, this.casesHCPChartOptions)
      this.casesHCPChartSeries[0].data = result.array2;
      this.casesHCPChartOptions.xaxis.categories = result.array1;
      this.casesHCPChartOptions.yaxis.categories = result.array1;
    },
    pathByOaramSearch() {
      this.$router.replace({
        name: "productsReport",
        query: { year: this.selectedYear },
      });
    },
    initialize(id, year) {
      // this.productsList = null;
      this.flagGetMainCharts = true;
      this.noChartsExist = false;
      this.listData = [];

      apiServices.get(`admin/product/${id}/show?year=${year}`).then((res) => {
        if (res.msg == "empty") {
          this.noChartsExist = true;
          this.flagGetMainCharts = false;
          return;
        } else {
          this.listData = res;
          this.patientAnalytics(res.analytics_report.patients_analytics);
          this.casesPSChart(res.patient_per_ps.data);
          this.casesPayerChart(res.patient_per_payer.data);
          this.casesHCPChart(res.patient_per_hcp.data);
          this.getMapData(res.patient_per_cities);
          this.ytd = res.ytd;
          this.growth = res.growth;
          this.flagGetMainCharts = false;
          this.average_dot = res.average_dot.average;
        }
      });
    },
  },
  created() {
    // this.selectedYear = ;
    this.getYearsFilterValidation();
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.initialize(this.$route.params.id, this.selectedYear);
        this.initializePatientList(
          this.$route.params.id,
          this.$route.query.year
        );
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    PatientList,
    VueApexCharts,
    SidebarProducts,
},
};
</script>
<style lang="scss" scoped>
@import "./_Products.scss";
</style>
